var winstonSarcinaJS = (function() {
  //Global variables

  //DOM elements
  var elements = {
    main: document.getElementsByTagName("main")[0],
    mainNavButton: document.getElementsByClassName("mainNav-toggle")[0],
    mainNavCloseButton: document.getElementsByClassName("mainNav-closeBtn")[0],
    subNavButtons: document.getElementsByClassName("subNav-btn"),
    cookiebanner: document.getElementsByClassName("cookiebanner")[0],
    cookiebannerButton: document.getElementById("cookiebannerButton")
  };

  // Public object
  var Public = {};

  // Page-specific public functions (if any)
  Public.example = function() {};

  // Private functions
  var toggleSubNav = function() {
    for (let i = 0; i < elements.subNavButtons.length; i++) {
      elements.subNavButtons[i].addEventListener("click", function() {
        let expanded = this.getAttribute("aria-expanded") === "true" || false;
        let className = this.classList[0];
        if (expanded) {
          this.classList.remove(className + "--expanded");
        } else {
          this.classList.add(className + "--expanded");
        }
        this.setAttribute("aria-expanded", !expanded);
        let nextLevel = this.nextElementSibling;
        nextLevel.hidden = !nextLevel.hidden;
      });
    }
  };

  var mainNavHandler = function() {
    elements.mainNavButton.addEventListener("click", function() {
      toggleMainNav();
    });
    elements.mainNavCloseButton.addEventListener("click", function(){
      toggleMainNav();
    });
  };

  var setCookie = function(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires + ";path=/";
  };

  var getCookie = function(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1);
      if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
    }
    return "";
  };

  var cookieHandler = function() {
    if (getCookie("GYBAcceptedCookie") === "") {
      elements.cookiebanner.classList.remove("cookiebanner--hide");
    }

    elements.cookiebannerButton.onclick = function() {
      setCookie("GYBAcceptedCookie", true, 365);
      elements.cookiebanner.classList.add("cookiebanner--hide");
    };
  };

  function onWindowScroll(event) {
    var className = "scrolled"
    if (window.scrollY === 0) {
      if (document.body.classList.contains(className)) {
        document.body.classList.remove(className);
      }
      return;
    }
    if (!document.body.classList.contains(className)) {
      document.body.classList.add(className);
    }
  }

  //HELPER FUNCTIONS
  function toggleMainNav() {
    let expanded = elements.mainNavButton.getAttribute("aria-expanded") === "true" || false;
    let className = elements.mainNavButton.classList[0];
    if (expanded) {
      document.body.classList.remove("navigating");
      elements.mainNavButton.classList.remove(className + "--expanded");
      elements.mainNavCloseButton.classList.remove("mainNav-closeBtn--expanded");
    } else {
      document.body.classList.add("navigating");
      elements.mainNavButton.classList.add(className + "--expanded");
      elements.mainNavCloseButton.classList.add("mainNav-closeBtn--expanded");
    }
    elements.mainNavButton.setAttribute("aria-expanded", !expanded);
    let nextLevel = elements.mainNavButton.nextElementSibling;
    nextLevel.hidden = !nextLevel.hidden;
  }

  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this;
      var args = arguments;
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      }, wait);
      if (callNow) func.apply(context, args);
    }
  }



  /** Initiate functions  */
  var init = (function () {
    var debouncedOnWindowScroll = debounce(onWindowScroll, 50);
    window.addEventListener("scroll", debouncedOnWindowScroll)
    toggleSubNav();
    mainNavHandler();
    if (elements.cookiebanner !== undefined) {
      cookieHandler();
    }
  })();

  return Public;
})((window.winstonSarcinaJS = window.winstonSarcinaJS || {}));
